import { USER_ROLE } from "constants/AppConstant";
import { IoNewspaperSharp } from "react-icons/io5";
import loginRoutes from "../../student/loginRoutes";
import entranceStudentProceedToLoginRoutes from "./entranceStudentProceedToLoginRoutes";
import entranceStudentRegisterRoutes from "./entranceStudentRegisterRoutes";
// import testSeriesStudentRegisterRoutes from "./testSeriesStudentRegisterRoutes";


const routes = {
  title: "REGISTER ",
  permittedRole: [USER_ROLE.PUBLIC],
  type: "group",
  icon: <IoNewspaperSharp />,
  path: ["/register"],
  children: [
    entranceStudentRegisterRoutes,
    // testSeriesStudentRegisterRoutes,
    entranceStudentProceedToLoginRoutes,
    loginRoutes,
  ],
};

export default routes;
